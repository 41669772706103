import { Injectable } from '@angular/core';
import { HttpService } from '@app/core';
import { User } from '../models/user';

@Injectable()
export class UserService {
  constructor(private http: HttpService) {}

  getUsersWithPermission(permission: string) {
    return this.http.get<User[]>(`users/userpermission/user/${permission}`);
  }
}
